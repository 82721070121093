import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "./CarouselReels.css";
import { imageUrl, saroAPI } from '../constant';
import { retailerNAME, tag } from '../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faVolumeUp, faVolumeMute, faMinusCircle, faPlusCircle, faExpand, faCompress, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useData } from '../../Context.js';
import { useNavigate } from "react-router-dom";
import { cacheMedia, getCachedMedia } from '../../utils/indexedDBHelper';



const CarouselReels = () => {
    const { data } = useData();

    const [cart, setCart] = useState([]); // State to manage the cart
    const [loadingProduct, setLoadingProduct] = useState(null); // Manage loader for a specific product
    const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
    const [videos, setVideos] = useState([]); // State for videos
    const videoRefs = useRef([]); // Dynamic refs for multiple videos
    const sliderRef = useRef(null); // Ref for the Slider
    const [isPlaying, setIsPlaying] = useState(true); // State to track play/pause
    const [isMuted, setIsMuted] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0); // Initialize active slide to the first video
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // For modal
    const [viewerId, setViewerId] = useState(null); // Add this state
    const [activeVideoId, setActiveVideoId] = useState(null); // Store active video ID
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const touchStartY = useRef(null); // ✅ Track swipe gestures
    const [isFullScreen, setIsFullScreen] = useState(false); // ✅ Track full-screen mode
    const [nextPageToken, setNextPageToken] = useState(null); // ✅ Track full-screen mode
    const [hasMoreVideos, setHasMoreVideos] = useState(true); // ✅ Controls further API calls & UI
    const [loadingStates, setLoadingStates] = useState({});



    useEffect(() => {
        const fetchProductsForCurrentVideo = async () => {
            if (!videos[activeVideoIndex]) return;

            const videoShowId = videos[activeVideoIndex].videoID; // Get current video ID

            // ✅ Avoid duplicate API calls for already fetched products
            if (products[videoShowId]) {
                //console.log(`⏩ Skipping API call, products already fetched for Video ID: ${videoShowId}`);
                return;
            }

            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";

            try {
                const response = await fetch(
                    `${saroAPI}/fetchVideoShowProducts?videoShowId=${videoShowId}&retailerName=${retailerNAME}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const responseData = await response.json();
                    //console.log(`🛍️ Products for Video ID ${videoShowId}:`, responseData);

                    if (responseData.products) {
                        setProducts(prevProducts => ({
                            ...prevProducts,
                            [videoShowId]: responseData.products // ✅ Store in state to prevent duplicate API calls
                        }));
                    }
                } else {
                    //console.error("❌ Failed to fetch products:", response.statusText);
                }
            } catch (error) {
                //console.error("❌ Error fetching products:", error.message);
            }
        };

        //fetchProductsForCurrentVideo();
    }, [activeVideoIndex, videos]); // ✅ Runs when video changes OR videos are loaded

    // ✅ New useEffect to fetch products for the first video when videos are initially loaded
    useEffect(() => {
        if (videos.length > 0) {
            setActiveVideoId(videos[0].videoID); // ✅ Set the first video as active
        }
    }, [videos]);

    useEffect(() => {
        //console.log('ActiveVideoIndex updated with value', activeVideoIndex, hasMoreVideos, videos.length);
        if (activeVideoIndex === videos.length - 2 && hasMoreVideos) {
            fetchVideos(); // ✅ Fetch more videos only if available
        }
    }, [activeVideoIndex]);

    useEffect(() => {


        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        if (!hasMoreVideos) return; // ✅ Stop fetching if no more videos
    
        //console.log('Calling getShopableVideos API');
        const getPageSize = () => (window.innerWidth <= 768 ? 3 : 6); // Mobile ≤ 768px
        let apiUrl = `${saroAPI}/getShopableVideos?retailerName=${retailerNAME}&pageSize=${getPageSize()}`;

        if (tag) {
            apiUrl += `&tag=${tag}`;
        }
    
        if (nextPageToken) {
            apiUrl += `&pageToken=${nextPageToken}`; // ✅ Only add pageToken if available
        }
    
        try {
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
            });
    
            if (response.ok) {
                const data = await response.json();
    
                // ✅ If server returns no videos, stop further API calls
                if (!data.data) {
                    //console.log("No more videos available.");
                    setHasMoreVideos(false); // ✅ Disable further fetching
                    return;
                }
    
                const sortedVideos = data.data.data
                    .filter(video => video.status !== 0) // ✅ Exclude inactive videos
                    .sort((a, b) => {
                        if (a.status === "active" && b.status !== "active") return -1;
                        if (a.status !== "active" && b.status === "active") return 1;
                        return a.orderBy - b.orderBy;
                    });
    
                setVideos(prevVideos => {
                    const mergedVideos = [...prevVideos, ...sortedVideos];
    
                    return mergedVideos
                        // .filter((video, index, self) =>
                        //     index === self.findIndex(v => v.videoID === video.videoID) // ✅ Remove duplicates
                        // )
                        .sort((a, b) => {
                            if (a.status === "active" && b.status !== "active") return -1;
                            if (a.status !== "active" && b.status === "active") return 1;
                            return a.orderBy - b.orderBy;
                        });
                });
    
                setNextPageToken(data.data.nextPageToken || null); // ✅ Stop calling API when no more pageToken
    
                if (!nextPageToken) {
                    sliderRef.current.slickGoTo(0);
                }

                // ✅ Cache videos in IndexedDB
            sortedVideos.forEach(video => {
                cacheMedia(video.videoUrl);
            });
            } else {
                //console.error("Failed to fetch videos:", response.statusText);
            }
        } catch (error) {
            //console.error("Error fetching videos:", error);
        }
    };

    const navigate = useNavigate();

    const goToFullscreenForMobile = (preFetchedVideos, videoID) => {
        navigate(`/Components/CarouselFullScreen/${videoID}`, { state: { preFetchedVideos } });
    };

    const goToFullscreenForDesktop = (preFetchedVideos, videoID) => {
        navigate(`/ShopableVideos`);
    };
    
    const toggleMuteUnmute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const handleVideoLoad = (videoID) => {
        setLoadingStates((prev) => ({
            ...prev,
            [videoID]: false, // Mark this specific video as loaded
        }));
    };

    const togglePlayPause = (event, index) => {
        if (index !== activeVideoIndex) return; // ✅ Only allow play/pause for the active video

        const videoElement = videoRefs.current[index]; // ✅ Get the video element
        if (!videoElement) return; // ✅ Prevent errors if ref is undefined

        const clickY = event.clientY;
        const clickX = event.clientX;
        const videoRect = videoElement.getBoundingClientRect();

        const videoHeight = videoElement.clientHeight;
        const videoWidth = videoElement.clientWidth;
        const isMobile = window.innerWidth <= 768; // ✅ Check if it's mobile view

        // ✅ Define Exclusion Zones (Top 20%, Bottom 20%, Left 20%, Right 20%)
        const topThreshold = videoRect.top + videoHeight * 0.2;
        const bottomThreshold = videoRect.top + videoHeight * 0.8;
        const leftThreshold = videoRect.left + videoWidth * 0.2;
        const rightThreshold = videoRect.left + videoWidth * 0.8;

        // if (isMobile) {
        //     // ✅ Only allow play/pause if clicked in the middle 60% (vertically & horizontally)
        //     if (clickY > topThreshold && clickY < bottomThreshold && clickX > leftThreshold && clickX < rightThreshold) {
        //         setIsPlaying((prevIsPlaying) => {
        //             const newPlayingState = !prevIsPlaying;
        //             newPlayingState ? videoElement.play() : videoElement.pause();
        //             return newPlayingState;
        //         });
        //     }
        // } else {
        //     // ✅ For web view, allow play/pause on the **entire video**
        //     setIsPlaying((prevIsPlaying) => {
        //         const newPlayingState = !prevIsPlaying;
        //         newPlayingState ? videoElement.play() : videoElement.pause();
        //         return newPlayingState;
        //     });
        // }
    };


    // We don't need to pause any video
    // useEffect(() => {
    //     if (videoRefs.current[activeVideoIndex]) {
    //         if (isPlaying) {
    //             videoRefs.current[activeVideoIndex].play();
    //         } else {
    //             videoRefs.current[activeVideoIndex].pause();
    //         }
    //     }
    // }, [activeVideoIndex, isPlaying]);


    // const closeModal = () => {
    //     setSelectedProduct(null); // Close modal
    //     document.body.classList.remove("modal-open"); // Remove class when modal closes
    // };

    const CustomPrevArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-prev-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    //closeModal();
                    onClick();
                }}
            >
                &#10094;
            </div>
        );
    };

    const CustomNextArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-next-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    //closeModal();
                    onClick();
                }}
            >
                &#10095;
            </div>
        );
    };

    const logVideoClickEvent = async () => {
        if (!videos[activeVideoIndex]) return;

        const payload = {
            retailerName: retailerNAME,  // Ensure retailer name is set
            videoShowId: videos[activeVideoIndex].videoID,
            eventType: "newVideoClick",
        };

        try {
            const response = await fetch(`${saroAPI}/logShoppableEvent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            // console.log("✅ logShoppableEvent API Response:", result);
        } catch (error) {
            // console.error("❌ Error logging video change event:", error);
        }
    };


    useEffect(() => {
        // console.log('🔄 Video changed, updating active video');

        // Call the API without affecting video playback
        

        // Call API **without waiting** for video to play
        if (videos[activeVideoIndex]) {
            debouncedLogEvent(videos[activeVideoIndex]); // ✅ Only calls API if user stops swiping
        }
    }, [activeVideoIndex]);

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedLogEvent = debounce(logVideoClickEvent, 3000); // 500ms delay

    

    const sliderSettings = {
        dots: false,
        infinite: false, // Disable infinite scrolling
        speed: 500,
        slidesToShow: 4, // Adjust based on design needs
        slidesToScroll: 1,
        // Disable center mode to prevent showing partial slides
        // If you want to keep centerMode, set centerPadding to '0px'
        //centerMode: true,
        //centerPadding: '0px',
        autoplay: false,
        autoplaySpeed: 4000,
        vertical: false,
        initialSlide: 0,
        arrows: true,

        afterChange: (current) => {
            // console.log(`Current active slide: ${current}`);
            // setActiveSlide(current);
            setActiveVideoIndex(current);
            setIsPlaying(true);
            setSelectedProduct(null);
            document.body.classList.remove("modal-open"); // Ensure modal class is removed
            setShowOptions(false);
        },
        // Optional: Customize arrows if needed
        //nextArrow: activeVideoIndex < videos.length - 1 ? <CustomNextArrow /> : null,
        nextArrow: (activeVideoIndex < videos.length - 2 || hasMoreVideos) ? <CustomNextArrow /> : null,
        prevArrow: activeVideoIndex > 0 ? <CustomPrevArrow /> : null,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    arrows: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    lazyLoad: true,
                    slidesToShow: 2, // Full-screen video
                    centerMode: false,
                    centerPadding: '0px',
                    slidesToScroll:2,
                    arrows: true, // Remove arrows for better mobile UX
                    //swipeToSlide: true, // Enable touch-based swiping
                    touchThreshold: 25, // ✅ Lower sensitivity for smoother touch scroll
                    draggable: true, // ✅ Allows smooth dragging
                },
            },
        ],
    };

    const handleTouchStart = (event) => {
        if (isFullScreen) {
            touchStartY.current = event.touches[0].clientY;
            // console.log("🛑 Swipe detected – closing product modal...");
            setSelectedProduct(null);  // ✅ Close modal immediately
            document.body.classList.remove("modal-open");
        }
    };

    const handleTouchMove = (event) => {
        if (!isFullScreen || touchStartY.current === null) return;

        const touchEndY = event.touches[0].clientY;
        const deltaY = touchStartY.current - touchEndY;

        if (Math.abs(deltaY) > 50) {
            // Swipe Up → Next Video
            if (deltaY > 0 && activeVideoIndex < videos.length - 1) {
                switchToVideo(activeVideoIndex + 1);
            }
            // Swipe Down → Previous Video
            else if (deltaY < 0 && activeVideoIndex > 0) {
                switchToVideo(activeVideoIndex - 1);
            }

            touchStartY.current = null;
        }
    };

    // ✅ Function to smoothly switch videos

    const switchToVideo = (newIndex) => {
        // console.log("🎥 Switching to Video:", newIndex);

        // ✅ Close product modal first
        setSelectedProduct(null);
        document.body.classList.remove("modal-open");

        setTimeout(() => {
            setActiveVideoIndex(newIndex);
            sliderRef.current.slickGoTo(newIndex); // ✅ Now switch videos

            videoRefs.current.forEach((video, index) => {
                if (video) {
                    if (index === newIndex) {
                       // video.play();
                    } else {
                        //video.pause();
                        video.currentTime = 0;
                    }
                }
            });
        }, 50); // ✅ Shorter delay for smoother UX
    };

    const handleFullscreen = () => {
        const videoContainer = document.querySelector(".slick-slider,.slick-initialized");

        if (!document.fullscreenElement) {
            // Enter Fullscreen Mode
            if (videoContainer.requestFullscreen) {
                videoContainer.requestFullscreen();
            } else if (videoContainer.mozRequestFullScreen) {
                videoContainer.mozRequestFullScreen();
            } else if (videoContainer.webkitRequestFullscreen) {
                videoContainer.webkitRequestFullscreen();
            } else if (videoContainer.msRequestFullscreen) {
                videoContainer.msRequestFullscreen();
            }
        } else {
            // Exit Fullscreen Mode
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };


    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFull = !!document.fullscreenElement;
            setIsFullScreen(isFull);

            if (isFull) {
                document.body.classList.add("fullscreen-mode");
            } else {
                document.body.classList.remove("fullscreen-mode");
            }
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.body.classList.remove("fullscreen-mode"); // Ensuring cleanup on unmount
        };
    }, []);

    // ✅ LISTEN FOR FULLSCREEN EXIT TO RESET VIDEO BEHAVIOR
    // useEffect(() => {
    //     const handleFullscreenChange = () => {
    //         if (!document.fullscreenElement) {
    //             setIsFullScreen(false);
    //             document.body.classList.remove("mobile-fullscreen-mode");

    //             // ✅ Restore previous state
    //             if (videoRefs.current[activeVideoIndex]) {
    //                 videoRefs.current[activeVideoIndex].play(); // Resume playing after exiting fullscreen
    //             }
    //         }
    //     };

    //     document.addEventListener("fullscreenchange", handleFullscreenChange);
    //     return () => {
    //         document.removeEventListener("fullscreenchange", handleFullscreenChange);
    //     };
    // }, [activeVideoIndex]);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                const videoElement = entry.target;
                const videoHeight = videoElement.offsetHeight; // Get video height
                const remainingVisibleHeight = entry.boundingClientRect.bottom - entry.boundingClientRect.top;
                const viewportHeight = window.innerHeight;

                // ✅ Ensure video stops ONLY when less than 10% is visible
                if (remainingVisibleHeight <= videoHeight * 0.1 || entry.intersectionRatio <= 0.1) {
                   // videoElement.pause();
                } else {
                    //videoElement.play().catch((error) => console.warn("Autoplay prevented:", error));
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Viewport
            rootMargin: "0px", // No extra margins
            threshold: [0.5], // ✅ Stops only when less than 10% is visible
        });

        videoRefs.current.forEach((video) => {
            if (video) observer.observe(video);
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) observer.unobserve(video);
            });
        };
    }, []);


    useEffect(() => {
        const checkVideoVisibility = () => {
            videoRefs.current.forEach((video, index) => {
                if (video) {
                    const rect = video.getBoundingClientRect();
                    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

                    if (isVisible) {
                        //video.play().catch(error => console.warn("Autoplay prevented:", error));
                    } else {
                        //video.pause();
                    }
                }
            });
        };

        window.addEventListener("scroll", checkVideoVisibility);
        return () => window.removeEventListener("scroll", checkVideoVisibility);
    }, []);

    //---------------------------------------------------------


    const [showOptions, setShowOptions] = useState(false);
    // Toggle popup visibility
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };


    return (
        <div className="saro-video-carousel">

            {/* Updated Slider Settings */}
            {/* {console.log("Rendering video carousel with videos:", videos)} */}
            <Slider  {...sliderSettings} ref={sliderRef}>
                {videos.map((video, index) => {
                    //console.log(`Rendering video #${index} with ID: ${video.videoID}`);
                    //console.log(`Video data ${index} : `,video);
                    const isActive = index === activeVideoIndex;
                    const isLoading = loadingStates[video.videoID] !== false;

                    return (
                        <div key={video.videoID} className="saro-carouselreels-video-slide" >
                            {/* Updated Video Section */}
                            <div className="saro-carouselreels-video-container">
                                <div className={`saro-video-wrapper ${isFullScreen ? "fullscreen-active" : ""}`}>
                                    <div className="saro-carousel-video-top-blur"></div>
                                    {isLoading && <div className="skeleton-loader"></div>}
                                    {/* <video
                                        className="carousel-video"
                                        poster={video.thumbnail}
                                        src={isActive ? video.videoUrl : undefined}
                                        preload="metadata"
                                        autoPlay={isActive}
                                        loop
                                        muted={isMuted}
                                        controls

                                        ref={(el) => (videoRefs.current[index] = el)} // Correctly assign ref for each video
                                        onClick={() => {
                                            console.log(`Video ${index} clicked`);
                                            sliderRef.current.slickGoTo(index);
                                        }}
                                        onTouchStart={handleTouchStart} // ✅ Detect swipe start
                                        onTouchMove={handleTouchMove} // ✅ Detect swipe movement
                                    ></video> */}
                                    {window.innerWidth <= 1024 ? (
                                        // Mobile View (Show only when isActive)
                                        <video
                                            className={`saro-carousel-video ${loadingStates[video.videoID] === false ? "loaded" : ""}`}
                                            key={video.thumbnail}
                                            // src={isActive ? video.videoUrl : ""}
                                            src={video.thumbnail}
                                            autoPlay={true}
                                            playsInline
                                            loop
                                            muted={true}
                                            ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                            onClick={() => {
                                                //console.log(`Video ${index} clicked`);
                                                //sliderRef.current.slickGoTo(index);
                                                //handleFullscreen(index);
                                                goToFullscreenForMobile(video, video.videoID);
                                            }}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            loading="lazy" // ✅ Lazy loading
                                            preload="auto"
                                            onLoadedData={() => handleVideoLoad(video.videoID)}
                                            onError={async (e) => {
                                                try {
                                                    const cachedUrl = await getCachedMedia(video.thumbnail);
                                                    e.target.src = cachedUrl;
                                                } catch {
                                                    //console.warn(`No cached version found for ${video.videoUrl}`);
                                                }
                                            }}
                                            // style={{
                                            //     backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                            //     backgroundSize: "cover",
                                            //     backgroundPosition: "center",
                                            // }}
                                        />
                                        //)
                                    ) : (
                                        // Desktop View (Always Show Video)
                                        <video
                                            className={`saro-carousel-video ${loadingStates[video.videoID] === false ? "loaded" : ""}`}
                                            key={video.thumbnail}
                                            // src={isActive ? video.videoUrl : ""}
                                            src={video.thumbnail}
                                            autoPlay={true}
                                            playsInline
                                            loop
                                            muted={true}
                                            ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                            onClick={() => {
                                                //console.log(`Video ${index} clicked`);
                                                //sliderRef.current.slickGoTo(index);
                                                //handleFullscreen(index);
                                                 goToFullscreenForDesktop(video, video.videoID);
                                            }}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            loading="lazy" // ✅ Lazy loading
                                            preload="auto"
                                            onLoadedData={() => handleVideoLoad(video.videoID)}
                                            // style={{
                                            //     backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                            //     backgroundSize: "cover",
                                            //     backgroundPosition: "center",
                                            // }}
                                        />
                                    )}

                                    


                                    {/* ✅ Show Play Icon Only When Video is Paused */}
                                    {!isPlaying && activeVideoIndex === index && (
                                        <div className="play-overlay">
                                            <FontAwesomeIcon icon={faPlay} className="play-icon" />
                                        </div>
                                    )}
                                    <div className="product-title-container">
                                        <div className="saro-carouselreels-video-title">
                                            {video.title}
                                        </div>
                                    </div>

                                    <div className="saro-video-title-container">

                                        {/* <FontAwesomeIcon
                                            icon={faEllipsisH}
                                            className="icon menu-icon"
                                            title="More Options"
                                            onClick={toggleOptions}
                                        /> */}
                                        {showOptions && isActive && (
                                            <div className="popup-menu">
                                                <div className="popup-inner-menu">
                                                    {/* Share Button */}
                                                    <button
                                                        className="popup-button b1"
                                                        onClick={async () => {
                                                            try {
                                                                // Copy the link to clipboard
                                                                await navigator.clipboard.writeText(video.productsUrls[0]);
                                                                toggleOptions();

                                                            } catch (error) {
                                                                //console.error("Failed to copy link:", error);
                                                            }
                                                        }}
                                                    >
                                                        Copy link
                                                    </button>


                                                    {/* Cancel Button */}
                                                    <button className="popup-button b2" onClick={toggleOptions}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>


                                        )}

                                        <p className="saro-video-title">
                                            {/* {video.title} */}
                                            {video.title.length > 50 ? `${video.title.slice(0, 50)}...` : video.title}
                                            </p>
                                        {/* <div
                                            className="saro-carouselreels-title-icons">
                                            <FontAwesomeIcon
                                                icon={isMuted ? faVolumeMute : faVolumeUp} // Shared mute state
                                                className={`icon speaker-icon ${isMuted ? "muted" : "unmuted"}`}
                                                title={isMuted ? "Unmute All Videos" : "Mute All Videos"}
                                                onClick={toggleMuteUnmute} // Toggle mute state for all videos
                                            />
                                            <FontAwesomeIcon
                                                icon={isFullScreen ? faCompress : faExpand}  // Change icon based on fullscreen state
                                                className="icon fullscreen-icon"
                                                title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
                                                onClick={() => handleFullscreen(index)} // ✅ Pass the clicked video index
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );


};

export default CarouselReels;

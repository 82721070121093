import React, { useState, useEffect } from 'react';
import PermissionPopup from './PermissionPopup'; // Import the new popup component
import './customStyle.css'; // Ensure this includes your styles for Permission
import { useNavigate } from 'react-router-dom';
import { useData } from '../Context';
import { useModalContext } from '../hooks/useModalState';
import { saroAPI, retailerNAME, imageUrl } from './constant';
import { FeatureFlags } from './featureFlags';
import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import boy from '../images/boy.png';
import { useConfig } from '../ConfigContext'; // Import the ConfigContext

const Permission = ({ onPageChange, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false); // State for showing the permission popup
  const [enabledFeatures, setEnabledFeatures] = useState([FeatureFlags.VOICE, FeatureFlags.VIDEO]); // State for enabled features

  // const { configData, textData, loading: configLoading } = useConfig(); // Accessing config and text data from context
  const { configData, textData, imageData, loading: configLoading } = useConfig();

  const navigate = useNavigate();
  const { setData } = useData();
  const { setModalState } = useModalContext();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const browserType = isSafari ? 'safari' : isChrome ? 'chrome' : 'other';

  const handleVoiceCallClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      if (stream) {
        startCallWithPermission({ audio: true, video: false }, FeatureFlags.VOICE);
      }
    } catch (error) {
      console.error('Permission denied or error:', error);
      setShowPermissionPopup(true); // Show the popup if permission is denied
    }
  };

  const handleVideoCallClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      if (stream) {
        startCallWithPermission({ audio: true, video: true }, FeatureFlags.VIDEO);
      }
    } catch (error) {
      console.error('Permission denied or error:', error);
      setShowPermissionPopup(true); // Show the popup on any view if permission is denied
    }
  };

  const startCallWithPermission = async (mediaConstraints, callType) => {
    try {
      const customerName = 'John';
      const retailerName = retailerNAME;

      if (mediaConstraints.audio || mediaConstraints.video) {
        fetchCallDetails(customerName, retailerName, callType);
      } else {
        console.error('No media permissions granted. Cannot start the call.');
        alert('You need to grant the required permissions to connect.');
      }
    } catch (error) {
      console.error('Error starting the call:', error);
      alert('There was a problem starting the call.');
    }
  };

  const fetchCallDetails = async (customerName, retailerName, callType) => {
    const retailerNameNew = retailerName;
    try {
      setIsLoading(true);
      const response = await fetch(`${saroAPI}/startCall`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          retailerName: retailerNameNew
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { sessionId, token, callUUID, userName: clerkName } = responseData.data;
        setData({
          'sessionID': sessionId,
          'token': token,
          'callUUID': callUUID,
          'userName': clerkName,
          'retailerName': retailerNameNew,
          'callType': callType
        });
        onPageChange("Main");
      } else {
        alert('Oh!!! All clerks are busy now.');
      }
    } catch (error) {
      alert('Oh!!! All clerks are busy now.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('isVideoOn', isVideoOn);
  }, [isVideoOn]);

  useEffect(() => {
    sessionStorage.setItem('isMicrophoneOn', isMicrophoneOn);
  }, [isMicrophoneOn]);

  useEffect(() => {
    setModalState('dynamic');
  }, []);

  if (configLoading) {
    return <div></div>; 
  }

  return (
    <div>
      {showPermissionPopup && (
        <PermissionPopup onClose={() => setShowPermissionPopup(false)} browserType={browserType} />
      )}

      <div className="centered-container">
        <div className="contentDiv">
          <h1 className="main-heading" style={{ color: configData.primaryColor || '#000' }}>
            {textData?.P3H1 } {/* Use description from textData */}
          </h1>
          <h2 className="sub-heading" style={{ color: configData.secondaryColor || '#333' }}>
            {textData?.P3H2 } {/* Use description from textData */}
          </h2>
          <h3 className="description-text" >
            {textData?.P3H3} {/* Use description from textData */}
          </h3>
          
          {isLoading && (
            <div className="overlay">
              <div className="loader"></div>
            </div>
          )}

          <div className="d-flex flex-column align-items-center">
            {enabledFeatures.includes(FeatureFlags.VOICE) && (
              <button className="perm-btn"  style={{ backgroundColor: configData?.BtnColor}} onClick={handleVoiceCallClick}>{textData?.P3B1 }</button>
            )}
            {enabledFeatures.includes(FeatureFlags.VIDEO) && (
              <button className="perm-btn-outline" style={{ borderColor: configData?.BtnColor}} onClick={handleVideoCallClick}>{textData?.P3B2 }</button>
            )}
          </div>

          <div className={window.innerWidth <= 768 ? "" : "footer-section"}>
                <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  <span className="powered-by">{textData?.logo}</span>
                  <img src={footerlogo1} alt="Saro Logo" className="footer-logo" />
                </a>
              </div>
        
        </div>

        <div className={window.innerWidth <= 768 ? "imageDiv11" : "imageDiv12"}>
          {/* <img src={`${imageUrl}${boy}`} alt="Permission Illustration" id='cimage1' /> */}
          <img src={window.innerWidth <= 768 ? (imageData?.P3MobileImage) : (imageData?.P3image)}  alt="Permission Illustration" id="cimage2" />
        </div>
      </div>
    </div>
  );
};

export default Permission;

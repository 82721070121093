import React, { useState, useEffect, useRef, useCallback } from 'react';
import './GoLiveCall.css';
import { startBroadcast, endCall, sendLikeSignal } from './GoLiveLanding';
import { useData } from '../../Context.js';
import { useModalContext } from '../../hooks/useModalState';
import livestatus from '../../images/live-black.png';
import zoom from '../../images/Zoom.svg';
import end from '../../images/End.svg';
import { useConfig } from '../../ConfigContext';
import { imageUrl, saroAPI } from '../constant';
import endcallicon from '../../images/endCall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { retailerNAME } from '../constant';
import AddToCart from '../../images/AddToCart.png';
import DeleteFromCart from '../../images/DeleteFromCart.png';
import Products from '../../images/Products.png';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faBars, faHeart, faChevronUp, faChevronDown, faCheckCircle, faTimes, faTimesCircle, faPlusCircle, faMinusCircle, faShoppingCart, faChevronLeft, faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons';
import { throttle } from 'lodash'; // Install lodash if not already installed
import heart from '../../images/HeartImage.png';
import share from '../../images/Share.png';
import chat from '../../images/Chat.png';
import product from '../../images/Product.png'

const Auto = ({ onPageChange }) => {
  const { data } = useData();
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [callStartTime, setCallStartTime] = useState(null);
  const { setModalState } = useModalContext();
  const [image, setImage] = useState();
  const [isStreamCreated, setIsStreamCreated] = useState(false);
  const [isStreamDestroyed, setIsStreamDestroyed] = useState(false);
  const { configData, textData, imageData, loading: isConfigLoading } = useConfig();
  const [productUrls, setProductUrls] = useState([]);
  const [products, setProducts] = useState([]); // State for fetched products
  const [cart, setCart] = useState([]); // State for the cart
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [isProductSectionVisible, setIsProductSectionVisible] = useState(true);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupIcon, setPopupIcon] = useState(null); // Define popupIcon state
  const [notification, setNotification] = useState(null);
  const [viewerId, setViewerId] = useState(null); // Add this state
  const [userCount, setUserCount] = useState(0); // Track the number of live users
  const [selectedProductId, setSelectedProductId] = useState(null); // New state for selected product ID
  const [isSessionInitialized, setIsSessionInitialized] = useState(false); // Track session initialization
  const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
  // const shopifyAccessToken = "17e1a365f61f5f64d76ba1f4f0988fd7";
  // const shopifyStoreUrl = "https://quickstart-ee4009eb.myshopify.com/api/2024-04/graphql.json";
  // const [likeCount, setLikeCount] = useState(0); // Add state for tracking likes
  // const showLikeAnimation = useAnimationState();
  const [likeCount, setLikeCount] = useState(0);
  const [userLikeCount, setUserLikeCount] = useState(0); // Likes received
  const sessionInstanceRef = useRef(null);
  const [isSendingLike, setIsSendingLike] = useState(false); // Lock to prevent duplicate likes
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(null);
  const shareMenuRef = useRef(null); // Ref for the share container
  const broadcastLink = `${window.location.origin}/broadcast/${data?.showID || "defaultID"}`;
  const [isLiked, setIsLiked] = useState(false); // Toggle heart color
const [floatingHearts, setFloatingHearts] = useState([]); 




  // State for controlling the center div popup visibility
  const [showCenterDiv, setShowCenterDiv] = useState(false);

  useEffect(() => {
    // Only run when both data and products are available
    if (data && data.sessionID && products.length > 0 && !isSessionInitialized) {
      const sessionId = data.sessionID;
      const token = data.token;
      const productHandles = data.productHandles;
      const showID = data.showID;
      const ShowTitle = data.title
      console.log("Initializing broadcast session");

      const initializeSession = async () => {
        if (!data?.showID) {
          console.error("showID is missing, cannot start the broadcast.");
          return;
        }
        const sessionInstance = await startBroadcast(
          data.showID, 
          setIsStreamCreated,
          setIsStreamDestroyed,
          setUserCount,
         // Pass dynamic showID here

          (productId) => {
            const product = products.find((p) => p.id === productId);
            if (product) setSelectedProduct(product);
            setTimeout(() => setSelectedProduct(null), 4000);
          },
          setUserLikeCount

        );

        if (sessionInstance) {
          sessionInstanceRef.current = sessionInstance;

          sessionInstance.on("streamCreated", () => {
            setIsStreamCreated(true);
            setIsStreamDestroyed(false);
          });

          sessionInstance.on("streamDestroyed", () => {
            setIsStreamCreated(false);
            setIsStreamDestroyed(true);
            showAvailableImage();
          });
        }

        setIsSessionInitialized(true); // Mark session as initialized
      };

      initializeSession();

      // Cleanup when component unmounts
      return () => {
        endCall();
      };
    }
  }, [data, products, isSessionInitialized]); // Dependencies are updated to only depend on data and products

  const handleZoomToggle = () => {
    const container = document.querySelector('.goliveCall-container'); // Target the entire container

    if (!container) {
      console.error('Element with class "goliveCall-container" not found.');
      return; // Exit if the element is not found
    }

    // Check if the element is already in fullscreen
    const isInFullscreen =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement;

    if (!isInFullscreen) {
      // Request fullscreen for the target container
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen(); // Safari
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen(); // Firefox
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen(); // IE/Edge
      }
    } else {
      // Exit fullscreen if already in fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
  };


  const fetchProductsByShowID = async (showID) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYWl6YSIsImlhdCI6MTcyMzgxODM2Mn0.JCOcOBZPO2-VOYU6IsjvqBKwsieNPWecFhV2IP-NjeA";

    try {
      const response = await fetch(`${saroAPI}/fetchProductDetailsFromShopify?liveShowId=${showID}&retailerName=${retailerNAME}
        `, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log("API response:", responseData);

        if (responseData.products) {
          setProducts(responseData.products); // API response now has "products" as a flat array
        } else {
          console.warn("No products found.");
          setProducts([]);
        }
      } else {
        console.error('API Error:', response.statusText);
        setProducts([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error.message);
      setProducts([]);
    }
  };

  const handleAddToCart = async (product) => {
    setLoadingProduct(product.id); // Start loader for this product
    try {
      const productId = product.id.split('/').pop();
      const variantId = product.variantID.split('/').pop();

      const response = await fetch(`${saroAPI}/addToCart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hardCodedToken}`,
        },
        body: JSON.stringify({
          retailerName: retailerNAME,
          liveShowId: data?.showID,
          productId,
          quantity: 1,
          title: product.title,
          variantId,
          price: product.price,
          image: product.image,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setCart((prevCart) => {
          const existingItem = prevCart.find((item) => item.id === product.id);
          if (existingItem) {
            return prevCart.map((item) =>
              item.id === product.id
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevCart, { ...product, quantity: 1 }];
        });
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    } finally {
      setLoadingProduct(null); // Stop loader
    }
  };



  const handleRemoveFromCart = async (product) => {
    setLoadingProduct(product.id); // Start loader for this product
    try {
      const productId = product.id.split('/').pop();

      const response = await fetch(`${saroAPI}/removeFromCart`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hardCodedToken}`,
        },
        body: JSON.stringify({
          retailerName: retailerNAME,
          viewerId: data?.viewerID || 'defaultViewerId',
          productId,
        }),
      });

      if (response.ok) {
        setCart((prevCart) => {
          const existingItem = prevCart.find((item) => item.id === product.id);
          if (existingItem.quantity === 1) {
            return prevCart.filter((item) => item.id !== product.id);
          }
          return prevCart.map((item) =>
            item.id === product.id
              ? { ...item, quantity: item.quantity - 1 }
              : item
          );
        });
      }
    } catch (error) {
      console.error('Error removing product from cart:', error);
    } finally {
      setLoadingProduct(null); // Stop loader
    }
  };


  const isInCart = (product) => {
    return cart.some((cartItem) => cartItem.id === product.id);
  };

  const handleCheckout = async () => {
    if (!data?.showID || !viewerId) {
      console.error("Missing required parameters for checkout.");
      setPopupMessage("Failed to initiate checkout. Missing data.");
      setPopupIcon(faTimesCircle);
      setShowPopup(true);
      return;
    }

    try {
      const response = await fetch(`${saroAPI}/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          retailerName: retailerNAME,
          liveShowId: data.showID,
          viewerId: viewerId, // Pass the viewerId from the Add to Cart response
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // console.log("Checkout API Response:", result);
        const checkoutUrl = result.checkoutUrl;

        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Redirect user to the checkout page
        } else {
          setPopupMessage("Checkout failed. Please try again.");
          setPopupIcon(faTimesCircle);
          setShowPopup(true);
        }
      } else {
        console.error("Checkout API Error:", result.message);
        setPopupMessage("Checkout failed. Please try again.");
        setPopupIcon(faTimesCircle);
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Checkout API Crash:", error.message);
      setPopupMessage("Error initiating checkout.");
      setPopupIcon(faTimesCircle);
      setShowPopup(true);
    }

    setTimeout(() => setShowPopup(false), 3000);
  };

  useEffect(() => {
    if (data?.showID) {
      // console.log("Fetching products using showID:", data.showID);
      fetchProductsByShowID(data.showID);
    } else {
      console.warn("No showID found in data.");
    }
  }, [data?.showID]);

  const showAvailableImage = () => {
    const availableImage = window.innerWidth <= 768 ? imageData?.P1MobileImage : imageData?.P1image;
    setImage(availableImage);
  };

  const handleProductSelection = (product) => {
    setSelectedProduct(product);
    setSelectedVariantId(product.variants.edges[0]?.node.id || '');
  };

  const handleVariantChange = (variantId) => {
    const variant = selectedProduct.variants.edges.find((variant) => variant.node.id === variantId);
    if (variant) {
      setSelectedVariantId(variantId);
      // Update selected product title, price, and image based on selected variant
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        title: variant.node.title,  // Update to show only the selected variant's title
        images: { edges: [{ node: { src: variant.node.image?.src || prevProduct.images.edges[0]?.node.src } }] },
        price: variant.node.priceV2.amount,
        currencyCode: variant.node.priceV2.currencyCode,
      }));
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setSelectedVariantId(product.variants.edges[0]?.node.id || '');
  };

  const handleToggleProductSection = () => {
    setIsProductSectionVisible(!isProductSectionVisible);
    const container = document.querySelector('.goliveCall-container');
    const productSection = document.querySelector('.product-section');
    const toggleButton = document.querySelector('.product-toggle');

    // Check if elements exist before modifying classes
    if (container && productSection && toggleButton) {
      if (!isProductSectionVisible) {
        // Expanding video to full width
        container.classList.add("full-width");
        productSection.classList.add("hidden");
        toggleButton.classList.add("full-width");
      } else {
        // Restoring to original layout
        container.classList.remove("full-width");
        productSection.classList.remove("hidden");
        toggleButton.classList.remove("full-width");
      }
    } else {
      console.error("One or more elements could not be found in the DOM.");
    }
  };

  // Function to handle like action



  // const throttledSendLikeSignal = throttle((session, count) => {
  //   sendLikeSignal(session, count);
  //   console.log(`Like signal sent with count: ${count}`);
  // }, 1000); // 1 second throttle

  const handleLikeBroadcast = useCallback(() => {
    if (isSendingLike) return; // Ignore if already processing a like

    setIsSendingLike(true); // Lock
    setLikeCount((prevCount) => {
      const newLikeCount = prevCount + 1;

      if (sessionInstanceRef.current) {
        sendLikeSignal(sessionInstanceRef.current, newLikeCount);
        console.log(`Like signal sent with count: ${newLikeCount}`);
      } else {
        console.error("Session instance not initialized yet.");
      }

      return newLikeCount;
    });

    // Reset the lock after a short delay to allow the next like
    setTimeout(() => setIsSendingLike(false), 500); // 500ms delay to avoid quick double clicks
  }, [isSendingLike]);

  const handleShareClick = () => {
    const broadcastLink = `${window.location.origin}/broadcast/${data?.showID || "defaultID"}`;

    if (navigator.share) {
      // Use Web Share API if available
      navigator.share({
        title: "Watch Live Broadcast!",
        text: "Check out this exciting live broadcast!",
        url: broadcastLink,
      })
        .then(() => console.log("Broadcast link shared successfully"))
        .catch((error) => console.error("Error sharing broadcast:", error));
    } else {
      // Fallback: Copy the link to clipboard
      navigator.clipboard.writeText(broadcastLink).then(() => {
        alert("Broadcast link copied to clipboard!");
      }).catch((error) => {
        console.error("Failed to copy broadcast link:", error);
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareMenuRef.current && !shareMenuRef.current.contains(event.target)) {
        setShowShareMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleHeartClick = () => {
    // Always trigger animation, even if already liked
    const newHeart = {
      id: Date.now(),
      left: Math.random() * 80 + 10, // Random horizontal position
    };
    setFloatingHearts((prev) => [...prev, newHeart]);
  
    // Remove the heart after animation completes
    setTimeout(() => {
      setFloatingHearts((prev) => prev.filter((heart) => heart.id !== newHeart.id));
    }, 2000);
  
    // If not liked yet, send the like signal
    if (!isLiked) {
      setIsLiked(true); // Mark as liked
      handleLikeBroadcast(); // Send like signal
    }
  };

  return (
    <div className="goliveCall-container">

      {notification && (
        <div className="notification-toast">
          <p>{notification}</p>
        </div>
      )}
      {/* Product Section */}
      {isStreamCreated && isProductSectionVisible && (
        <div className={`product-section ${window.innerWidth <= 768 ? 'product-section-mobile' : ''}`}>
          <div className="d-flex justify-content-between align-items-center">
            <h2 style={{ marginLeft: '10px' }}>{textData?.P2BT1}</h2>
            {cart.length > 0 && (
              <button className="checkout-btn" onClick={handleCheckout}>
                {textData?.P2BTBtn1 || "Checkout"}
                <FontAwesomeIcon icon={faShoppingCart} style={{ marginLeft: '5px' }} />
              </button>
            )}

          </div>
          <div className="product-list">
            {selectedProduct ? (
              <div className="product-details">
                <div className="close-icon" onClick={() => setSelectedProduct(null)}>
                  <FontAwesomeIcon icon={faTimes} size="lg" style={{ cursor: 'pointer' }} />
                </div>
                <img
                  src={selectedProduct.image || ''} // Use simplified field for selected product image
                  alt={selectedProduct.title || 'Product'}
                  className="product-image-large"
                />
                <p className="product-title">{selectedProduct.title || 'Unknown Title'}</p>
                <p className="product-price">
                  {selectedProduct.price || 'N/A'} USD
                </p>
                {/* Check if variants are available for the product */}
                {selectedProduct?.variants?.length > 1 && (
                  <select
                    className="variant-select"
                    value={selectedVariantId}
                    onChange={(e) => handleVariantChange(e.target.value)}
                  >
                    {selectedProduct.variants.map((variant) => (
                      <option key={variant.variantID} value={variant.variantID}>
                        {variant.title || 'Variant'} - {variant.price || 'N/A'} USD
                      </option>
                    ))}
                  </select>
                )}

                <button
                  onClick={() => handleAddToCart(selectedProduct)}
                  className="add-to-cart-btn"
                >
                  {textData?.P2BTBtn2 || 'Add to Cart'}
                </button>
              </div>
            ) : (
              products.map((product) => (
                <React.Fragment key={product.id}>
                  <div className="product-item d-flex align-items-center">
                    <img
                      src={product.image || ''}
                      alt={product.title || 'Product'}
                      className="product-image"
                      // onClick={() => handleProductClick(product)}
                      style={{ cursor: 'pointer' }}
                    />
                    <div className="product-info">
                      <p
                        className="product-title"
                      // onClick={() => handleProductClick(product)}
                      // style={{ cursor: 'pointer' }}
                      >
                        {product.title || 'Unknown Title'}
                      </p>
                      <p
                        className="product-price"
                        // onClick={() => handleProductClick(product)}
                        style={{ cursor: 'pointer' }}
                      >
                        {product.price || 'N/A'} USD
                      </p>
                    </div>
                    <div className="add-to-cart-icon">
                      {loadingProduct === product.id ? (
                        <div className="spinner"></div> // Loader
                      ) : isInCart(product) ? (
                        <div className="quantity-control">
                          <img
                            src={`${imageUrl}${DeleteFromCart}`}
                            className="livestatusimg"
                            width="25px"
                            alt="Remove"
                            onClick={() => handleRemoveFromCart(product)}
                          />
                          <span className="quantity-number">
                            {cart.find((cartItem) => cartItem.id === product.id)?.quantity || 1}
                          </span>
                          <img
                            src={`${imageUrl}${AddToCart}`}
                            className="livestatusimg"
                            width="25px"
                            alt="Add"
                            onClick={() => handleAddToCart(product)}
                          />
                        </div>
                      ) : (
                        <img
                          src={`${imageUrl}${AddToCart}`}
                          className="livestatusimg"
                          width="25px"
                          alt="Add"
                          onClick={() => handleAddToCart(product)}
                        />
                      )}
                    </div>

                  </div>
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      )}



      {/* Video Section */}
      <div
        className="goLiveCall-video-section"
        style={{
          width: isStreamCreated && isProductSectionVisible && window.innerWidth > 768 ? '60%' : '100%',
        }}
      >
        <div className="overlayy d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between livebtn z_index">
            {selectedProduct && (
              <div className="product-popup">
                <div className="product-details2">
                  <img
                    src={selectedProduct.image}
                    alt={selectedProduct.title}
                    className="product-image"
                  />
                  <div className="product-info2">
                    <h3 className="product-title2">{selectedProduct.title}</h3>
                    <p className="product-price2">${selectedProduct.price}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="video-overlay">
              <div className="liveLogoContainer">
                {/* Top-left image */}
                <img
                  src="https://www.shutterstock.com/image-photo/mercedes-benz-logo-on-wall-260nw-2394871391.jpg"
                  alt="Top Left Icon"
                  className="top-left-image"
                />

                {/* Title and Live Indicator */}
                <div className="live-title">
                  {data?.ShowTitle || "Default Show Title"}
                  <div className="live-badge">
                    <span className="live-text">LIVE</span>
                    <span className="live-circle"></span>
                  </div>
                </div>
              </div>

              {/* Blur overlay */}
              <div className="blur-overlay"></div>
            </div>

            {/* Web Product Toggle Button (Chevron Left/Right) */}

            {/* Live User Count */}
            {isStreamCreated && (
              <>
                {/* Live User Count */}
                <div className="live-user-count">
                  <div className="viewer-count">
                    <FontAwesomeIcon icon={faEye} className="viewer-icon" />
                    <span className="viewer-number">{userCount}</span>
                  </div>
                </div>

                {/* Right Icons */}
                <div className="right-icons">
                <div className="heart-container">
  {/* Heart Icon */}
  <img
    src={heart} // Use the same heart image
    alt="Like"
    className="icon-style"
    onClick={handleHeartClick}
    style={{ filter: isLiked ? "grayscale(0%)" : "grayscale(100%)", color: isLiked ? "red" : "black" }}
  />

  {/* Floating Heart Animation */}
  {floatingHearts.map((heart) => (
    <div
      key={heart.id}
      className="floating-heart"
      style={{ left: `${heart.left}%` }}
    >
      ❤️
    </div>
  ))}
</div>


                  <div className="share-container" ref={shareMenuRef}>
                    <img
                      src={share}
                      alt="Share"
                      className="icon-style"
                      onClick={() => setShowShareMenu((prev) => !prev)} // Toggle share menu
                    />

                    {/* Share Popup */}
                    {showShareMenu && (
                      <div className="share-popup">
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(broadcastLink)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebook} size="2x" className="social-icon facebook" />
                        </a>
                        <a
                          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(broadcastLink)}&text=Check out this live broadcast!`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} size="2x" className="social-icon twitter" />
                        </a>
                        <a
                          href={`https://api.whatsapp.com/send?text=${encodeURIComponent("Check out this live broadcast: " + broadcastLink)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} size="2x" className="social-icon whatsapp" />
                        </a>
                        <a
                          href={`mailto:?subject=Live Broadcast&body=Check out this live broadcast: ${broadcastLink}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faEnvelope} size="2x" className="social-icon email" />
                        </a>
                      </div>
                    )}
                  </div>

                  {/* Product Toggle */}
                  <img
                    src={product}
                    alt="product"
                    className="icon-style"
                    onClick={handleToggleProductSection}
                  />
                </div>
              </>
            )}



            {/* {showLikeAnimation && (
        <div className="animate-like">
          <FontAwesomeIcon icon={faHeart} style={{ fontSize: "3rem", color: "red" }} />
        </div>
      )} */}

          </div>

          <div className="video-call" id="subscriber">
            {/* Like Button */}
            {/* {sessionInstanceRef.current && (
  <div className="like-button">
<FontAwesomeIcon
  icon={faHeart}
  onClick={(e) => {
    e.stopPropagation(); // Prevent any event bubbling causing duplicate triggers
    handleLikeBroadcast();
  }}
  style={{ cursor: "pointer", color: "red" }}
/>

  </div>
)} */}

            {isStreamDestroyed ? (
              <img
                src={window.innerWidth <= 768 ? imageData?.B2Mimage : imageData?.B2image}
                alt="Stream Ended"
                className="call-image"
              />

            ) : !isStreamCreated ? (
              <img
                src={window.innerWidth <= 768 ? imageData?.B1Mimage : imageData?.B1image}
                alt="Call State"
                className="call-image"
              />

            ) : (
              <div className="call-screen">
                {/* Streaming component will be displayed here */}
              </div>
            )}
          </div>

          {/* Zoom and End Call Controls */}
          <div
            className="videoNavbar"
          // style={{
          //   position: 'absolute',
          //   bottom: '10px',
          //   left: '30%',
          //   display: 'flex',
          //   gap: '50px',
          // }}
          >
            <div className="button-container" >
              <div onClick={handleZoomToggle}>
                <img
                  src={`${imageUrl}${zoom}`}
                  alt="Zoom"
                  style={{ width: '100px', height: '45px' }}
                />
              </div>
              <div onClick={() => setShowCenterDiv(true)}>
                <img
                  src={`${imageUrl}${end}`}
                  alt="End Call"
                  style={{ width: '100px', height: '45px' }}
                />
              </div>
            </div>
          </div>


        </div>
      </div>



      {/* Center Div for End Call Confirmation */}
      {showCenterDiv && (
        <div
          id="centerDiv"
          className="centerDiv d-flex flex-column justify-content-evenly"
          style={{
            position: 'fixed',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '240px',
            height: '230px',
            backgroundColor: '#fff',
            border: '2px solid black',
            borderRadius: '20px',
            padding: '20px 0',
          }}
        >
          <div className="headicon d-flex justify-content-center">
            <img src={endcallicon} alt="End Call Icon" className="imgicon5" />
          </div>
          <h3 style={{ fontSize: '18px', marginBottom: '15px', textAlign: 'center' }}>
            {textData?.P4P1}
          </h3>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
            <button
              onClick={() => {
                endCall();
                onPageChange("BroadcastFeedback");
              }}
              style={{
                width: '100px',
                height: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {textData?.P4PBtn1}
            </button>
            <button
              onClick={() => setShowCenterDiv(false)}
              style={{
                width: '100px',
                height: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid black',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {textData?.P4PBtn2}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auto;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { useNavigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {saroAPI} from '../src/Components/constant';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('ShopARonline')
);



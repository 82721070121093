import React, { useState, useEffect } from 'react';
import './GoLive.css';
import { useConfig } from '../ConfigContext';
import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import defaultImage from '../images/brandmodalimg.png';
import { saroAPI } from './constant';
import { useNavigate } from 'react-router-dom';
import { useData } from '../Context';
import { useModalContext } from '../hooks/useModalState';
import { retailerNAME } from './constant';



const GoLive = ({ onPageChange }) => {
  const { configData, textData, imageData, loading: isConfigLoading } = useConfig(); // Fetch config using useConfig
  const [countdown, setCountdown] = useState(null);
  const [isLessThan24Hours, setIsLessThan24Hours] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [noRecentShow, setNoRecentShow] = useState(false);
  const [isShowtime, setIsShowtime] = useState(false);
  const [show, setShow] = useState(null); // Added state for show details

  const { setData, data } = useData();
  const navigate = useNavigate();
  const { setModalState } = useModalContext();

  const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";

  const fetchShowDetails = async () => {
    try {
        const response = await fetch(`${saroAPI}/getClosestUpcomingShow?retailerName=${retailerNAME}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${hardCodedToken}`,
            },
        });

        const result = await response.json();
        console.log('Full API Response:', result);

        if (result?.data?.data) {
            const show = result.data.data;
            setShow(show); // Store the show data in state

            // Extract product handles from URLs
            const productHandles = show.productsUrls ? 
            show.productsUrls.map(url => url.split("/products/")[1]) : [];
            console.log('Extracted Product Handles:', productHandles);
        
            setData({ sessionID: show.sessionID, token: show.token, productHandles,showID: show.showID,ShowTitle : show.title });

            if (show.thumbnailUrl) {
                setThumbnail(show.thumbnailUrl); // Set thumbnail if available
            } else {
                setThumbnail(defaultImage); // Fall back to defaultImage
            }

            const showTimeUTC = new Date(show.showTime);
            const endTimeUTC = new Date(show.endTime);
            const currentTimeUTC = new Date(new Date().toUTCString());
            const timeDifference = showTimeUTC - currentTimeUTC;

            if (timeDifference > 0) {
                console.log('Show is upcoming');
                setCountdown(timeDifference);
                setIsLessThan24Hours(true);
                setNoRecentShow(false);
            } else if (currentTimeUTC >= showTimeUTC && currentTimeUTC <= endTimeUTC) {
                console.log('Show is live');
                setIsShowtime(true);
                setNoRecentShow(false);

                if (show.sessionID && show.token) {
                    // console.log('sessionID:', show.sessionID);
                    // console.log('token:', show.token);
                    setData({ sessionID: show.sessionID, token: show.token, productHandles: productHandles ,showID: show.showID, ShowTitle:show.title});
                    console.log("Setting sessionId token and product handler");
                } else {
                    console.error('sessionID or token missing in API response.');
                }
            } else {
                setNoRecentShow(true);
            }
        } else {
            console.error('No data found in the API response:', result);
            setNoRecentShow(true);
        }

        setIsLoading(false);
    } catch (error) {
        console.error('Error fetching show details:', error);
        setIsLoading(false);
        setNoRecentShow(true);
    }
};


  useEffect(() => {
    setModalState('dynamic');
    fetchShowDetails();
  }, []);

  useEffect(() => {
    if (countdown && countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1000;
          if (newCountdown <= 0) {
            clearInterval(interval);
            setIsShowtime(true);
            return 0;
          }
          return newCountdown;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  const addToCalendar = (showTime, endTime, title = "Live Broadcast") => {
    const showTimeUTC = new Date(showTime).toISOString().replace(/[-:]/g, '').split('.')[0] + "Z";
    const endTimeUTC = new Date(endTime).toISOString().replace(/[-:]/g, '').split('.')[0] + "Z";

    const calendarEvent = `
  BEGIN:VCALENDAR
  VERSION:2.0
  BEGIN:VEVENT
  DTSTART:${showTimeUTC}
  DTEND:${endTimeUTC}
  SUMMARY:${title}
  DESCRIPTION:Join us for a live broadcast
  END:VEVENT
  END:VCALENDAR
    `;

    const blob = new Blob([calendarEvent], { type: 'text/calendar' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'live_broadcast_event.ics';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const formatCountdown = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleJoinBroadcast = () => {
    console.log('Global Data:', data);
    if (!data || !data.sessionID || !data.token) {
      alert('Cannot join broadcast, missing session details.');
      return;
    }

    setData({
      
      sessionID: data.sessionID,
      token: data.token,
      productHandles: data.productHandles,
      showID: show.showID,
      ShowTitle: show.title
    });

    onPageChange("GoLiveMain");
  };

  if (isConfigLoading) {
    return (
      <div>
      <div className="small-overlay">
        <div className="small-loader"></div>
      </div>
      </div>
    ); // Show loading while fetching config
  }

  if (isLoading) {
    return (
      <div>
      <div className="small-overlay">
        <div className="small-loader"></div>
      </div>
      </div>
    );
  }

  if (noRecentShow) {
    return (
      <div className="golive-container">
        <div className="content-section">
          <h1 className="golive-main-heading" style={{ color: configData?.primaryColor }}>
            {textData?.P1BT1}
          </h1>
          <p className="golive-sub-text" style={{ color: configData?.secondaryColor }}>
            {textData?.P1BT2}
          </p>
        </div>
        <div className="image-section">
          <img src={imageData?.P2Image} alt="No Recent Show" className="golive-image" />
        </div>
      </div>
    );
  }

  return (
    <div className="golive-container">
      <div className="content-section">
        <h1 className="golive-main-heading" style={{ color: configData?.primaryColor }}>
          {isShowtime ? textData?.P2H1 : textData?.P1H1}
        </h1>
        <h2 className="golive-sub-text" style={{ color: configData?.primaryColor }}>
          {isShowtime ? textData?.P2H3 : textData?.P1H2}
        </h2>

        {isLessThan24Hours && countdown !== null && !isShowtime && (
          <p className="golive-countdown" style={{ color: configData?.secondaryColor }}>
            {formatCountdown(countdown)}
          </p>
        )}

        {isShowtime ? (
          <button className="goLive-btn" onClick={handleJoinBroadcast}>
            {textData?.P1Btn2 }
          </button>
        ) : (
          <button
            className="goLive-btn"
            onClick={() => show && addToCalendar(show.showTime, show.endTime, "Live Broadcast")}
          >
            {textData?.P1Btn1 }
          </button>
        )}
        <footer className="golive-footer">
          <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
            <span className="powered-by">{textData?.logo}</span>
            <img src={footerlogo1} alt="Saro Logo" className="footer-logo" />
          </a>
        </footer>
      </div>

      <div className="image-section">
        <img src={thumbnail} alt="Live Show" className="golive-image" />
      </div>
    </div>
  );
};

export default GoLive;
import { useEffect, useRef } from "react";
import Hls from "hls.js";

const CarouselFullScreen = () => {
  const videoRef = useRef(null);
  const videoSrc = "https://storage.googleapis.com/wakapapa-fd7cb.appspot.com/retailerUpload/infinix/2-25/saro-8c472db6-9908-46e7-8f48-f3bc2ab4d315-playlist.m3u8";

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });

      return () => {
        hls.destroy();
      };
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoSrc;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
    }
  }, []);

  return (
    <div className="saro-video-container">
      <video ref={videoRef} controls className="saro-video-player" style={{ width: "100%", maxWidth: "800px" }} />
    </div>
  );
};

export default CarouselFullScreen;

import React from 'react';
import './App.css';
import Header from './Components/Header';
import Body from './Components/Body';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Headers from './Components/SpeakPage/Headers';
import Main from './Components/CallPage/Main';
import FeedPage from './Components/FeedBack/FeedPage';
import FirstPage from './Components/FirstPage/FirstPage';
import WebPage from './Components/WebPage/WebPage';
import Permission from './Components/Permission';
import GoLive from './Components/GoLive'
import { DataProvider } from './Context';
import { ConfigProvider } from './ConfigContext'; // Add the new ConfigProvider

function App() {
  return (
    <Router>
      <div className="app-container">
      <DataProvider>
        <ConfigProvider> {/* Wrap everything in ConfigProvider */}
        <Routes>
        {/* Default route */}
        <Route path="*" element={<WebPage />} />
        <Route path="Components/GoLive" element={<GoLive />} />
          <Route path="Components/Body" element={<Body />} />
          <Route path="SpeakPage/Headers" element={<Headers />} /> {/* Define route for Link.jsx page */}
          {/* Define other routes here */}
          <Route path="CallPage/Main" element={<Main />} />
          <Route path="FeedBack/FeedPage" element={<FeedPage />} />
          <Route path="FirstPage/FirstPage" element={<FirstPage />} />
          <Route path="WebPage/WebPage" element={<WebPage />} />
          <Route path="Components/Permission" element={<Permission />} />
          
        </Routes>
        </ConfigProvider>
        </DataProvider>
      </div>
    </Router>
  );
}

export default App;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { saroAPI, retailerNAME } from './Components/constant'; // Adjust the path as necessary

const ConfigContext = createContext();

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }) => {
  const [configData, setConfigData] = useState(null);  // For color data
  const [textData, setTextData] = useState(null);      // For text data
  const [imageData, setImageData] = useState(null);    // For image data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Calling getConfigProperties API");

    const fetchConfig = async () => {
      const token = "your-token-here"; // Replace with your token

      try {
        // API call to fetch color configuration
        const colorResponse = await fetch(`${saroAPI}/getConfigProperties`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ retailerName: retailerNAME, category: 'colors' }),
        });

        const colorData = await colorResponse.json();

        if (colorResponse.ok && colorData.data) {
          setConfigData(colorData.data); // Store color data
        } else {
          console.error("Failed to fetch color config data");
        }

        // API call to fetch text configuration
        const textResponse = await fetch(`${saroAPI}/getConfigProperties`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ retailerName: retailerNAME, category: 'text' }),
        });

        const textResponseData = await textResponse.json();

        if (textResponse.ok && textResponseData.data) {
          setTextData(textResponseData.data); // Store text data
        } else {
          console.error("Failed to fetch text data");
        }

        // API call to fetch image configuration
        const imageResponse = await fetch(`${saroAPI}/getConfigProperties`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ retailerName: retailerNAME, category: 'images' }),
        });

        const imageDataResponse = await imageResponse.json();

        if (imageResponse.ok && imageDataResponse.data) {
          setImageData(imageDataResponse.data); // Store image data
        } else {
          console.error("Failed to fetch image data");
        }

      } catch (error) {
        console.error("Error fetching config, text, or image data:", error);
      } finally {
        setLoading(false); // Stop loading once all calls are done
      }
    };

    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ configData, textData, imageData, loading }}>
      {children}
    </ConfigContext.Provider>
  );
};

import { saroAPI } from '../constant';

// Import OpenTok client library
const OT = require('@opentok/client');

// Declare session variable at a higher scope
let session;
let publisher;
let customerCallUUID;
let customerRetailerName;
let isMicrophoneEnabled = true; // Global variable for microphone state
let isVideoEnabled = true; // Global variable for video state

// Declare timer variables
let timerNextClerk;
let timer;
let nextClerkTime = 20000;
let callTimeOutTimer = 60000; // Increase the call timeout if needed

let handleVideoToggleRef = null;

// Define setCallStartTime function
function setCallStartTime() {
  // Implementation of setCallStartTime
  // For example, you can log the call start time
  //console.log('Call start time set');
}

function getURLParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

const retailerName = getURLParameter('retailerName');

// Start the call
export function startCall(sessionId, token, callUUID, clerkName, retailerName, callType) {
  console.log("Session Id",sessionId)
  const apiKey = '9221d0f6-71bd-48a0-976d-5355c89962cf'; // Your API Key
  customerRetailerName = retailerName;
  customerCallUUID = callUUID;

  // Start timer to end the call after specified timeout

  timer = setTimeout(callTimeout, callTimeOutTimer);
  

  timerNextClerk = setTimeout(() => {
    findNextClerkRepeatedly(clerkName, retailerName, token, sessionId, callUUID);
  }, nextClerkTime);

  // Initialize session
  session = OT.initSession(apiKey, sessionId);

  // Create a publisher
  const publisherOptions = {
    insertMode: "append",
    width: "100%",
    height: "100%",
    style: { buttonDisplayMode: 'off' },
    audioSource: true, // Microphone is mandatory
  };

  if (callType === 'video') {
    // Check if camera permission is granted and add videoSource if true
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        publisherOptions.videoSource = stream.getVideoTracks().length > 0 ? undefined : null;
        publisher = OT.initPublisher("publisher", publisherOptions, handleCallback);
      })
      .catch(error => {
        publisherOptions.videoSource = null; // Camera is optional, so handle it accordingly
        publisher = OT.initPublisher("publisher", publisherOptions, handleCallback);
      });
  } else if (callType === 'singleCamera') {
    // For single camera call, disable user's camera but keep audio enabled
    publisherOptions.videoSource = null;
    publisher = OT.initPublisher("publisher", publisherOptions, handleCallback);
  } else {
    // For voice call, disable video for both user and clerk
    publisherOptions.videoSource = null;
    publisher = OT.initPublisher("publisher", publisherOptions, handleCallback);
  }
  console.log("Token:", token);
  // Connect to the session
  session.connect(token, error => {
    
    if (error) {
      handleCallback(error);
    } else {
      session.publish(publisher, handleCallback);
    }
  });

  // Subscribe to a newly created stream
  session.on("streamCreated", event => {
    console.log("stream Created");
    clearTimeout(timer);
    clearTimeout(timerNextClerk); // Reset call timeout timer
    //console.log('Subscriber joined the session.');
    setCallStartTime(new Date()); // Update call start time when subscriber joins
    session.subscribe(
      event.stream,
      "subscriber",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        style: { buttonDisplayMode: 'off' }
      },
      handleCallback
    );
  });

  session.on('streamDestroyed', event => {
    //console.log('Subscriber left the call with reason: ', event.reason);
    endCall();
    window.location.assign('/');
  });

  return session;
}

// End the call
export async function endCall() {
  clearTimeout(timer);
  clearTimeout(timerNextClerk); // Clear call timeout timer
  endSession();
  await callEndApi();
  //console.log('Call ended');
}

// End session function
async function endSession() {
  if (session) {
    session.disconnect();
    //console.log("Session disconnected");
  } else {
    //console.log("Session already disconnected");
  }
}

// Callback handler
function handleCallback(error) {
  if (error) {
    //console.log("error: " + error.message);
  } else {
    //console.log("callback success");
  }
}

// API call to end the call
async function callEndApi() {
  const callUUID = customerCallUUID;
  const retailerName = customerRetailerName;

  try {
    const response = await fetch(`${saroAPI}/endCall`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "retailerName": retailerName,
        "callUUID": callUUID,
        "callEndedBy": 0
      }),
    });

    if (!response.ok) {
      console.error('API Error:', response.statusText);
    }
  } catch (error) {
    console.error('API Crash Error:', error.message);
  }
}

// Timeout function for call timeout
async function callTimeout() {
   //clearTimeout(timerNextClerk);

  // await endSession();

  // await callEndApi();

   // Adding a delay of 2 seconds (2000 milliseconds)
  //await new Promise(resolve => setTimeout(resolve, 2000));
  
  // window.alert("Oh no!!! All clerks are busy at this moment. Please try again in some time.");
  window.alert("أوه لا!!! جميع الكتبة مشغولون في هذه اللحظة. يرجى المحاولة مرة أخرى في بعض الوقت");

  

  if (handleVideoToggleRef) {
    handleVideoToggleRef(); // Call the stored handleVideoToggle method
  }
  
}

// Function to find the next available clerk
function findNextClerkRepeatedly(customerName, retailerName, token, sessionID, callUUID) {
  findNextClerk(customerName, retailerName, token, sessionID, callUUID);
  // You may need to implement a different strategy here if the call should be continued without interruption
  timerNextClerk = setTimeout(() => {
    findNextClerkRepeatedly(customerName, retailerName, token, sessionID, callUUID);
  }, nextClerkTime);
}

// API call to find the next available clerk
async function findNextClerk(customerName, retailerName, token, sessionID, callUUID) {
  //console.log('Finding next available clerk');

  try {
    // console.log(JSON.stringify({
    //   "retailerName": retailerName,
    //   "callUUID": callUUID,
    //   "sessionID": sessionID,
    //   "token": token,
    //   "userName": customerName
    // }));
    const response = await fetch(`${saroAPI}/findNextClerk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "retailerName": retailerName,
        "callUUID": callUUID,
        "sessionID": sessionID,
        "token": token,
        "userName": customerName
      }),
    });

    if (!response.ok) {
      console.error('API Error:', response.statusText);
      callTimeout();
    }
  } catch (error) {
    console.error('API Crash Error:', error.message);
  }
}

// Function to toggle video
export function toggleVideo(isVideoOn) {
  if (!isVideoEnabled) {
    return; // Prevent video from being toggled if it's disabled
  }
  publisher.publishVideo(!isVideoOn);
}

// Function to toggle microphone
export function toggleMicrophone(isMicrophoneOn) {
  publisher.publishAudio(!isMicrophoneOn);
}

// Function to set the handleVideoToggle reference
export function setHandleVideoToggleRef(handleVideoToggle) {
  handleVideoToggleRef = handleVideoToggle;
}

import React, { useState, useEffect } from 'react';
import './Popup.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useData } from '../Context';
import { useModalContext } from '../hooks/useModalState';
import './customStyle.css'; // Import your CSS file
import './NAcustomStyle.css';

import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import micIcon from '../images/mic.png';
import available from '../images/ClerksAvailable.png';
import { useConfig } from '../ConfigContext'; // Import useConfig
import { saroAPI, retailerNAME, imageUrl } from './constant';
import brandimg from '../images/brandmodalimg.png'; // Correctly import the image


const Body = ({ onPageChange, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showNoClerksPopup, setShowNoClerksPopup] = useState(false);
  const [clerksAvailable, setClerksAvailable] = useState(true);
  const { configData, textData, imageData, loading: isConfigLoading } = useConfig(); // Fetch config using useConfig

  const navigate = useNavigate();
  const { setData } = useData();
  const { setModalState } = useModalContext();

  const checkClerkAvailability = async (retailerName) => {
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmYWl6YSIsImlhdCI6MTcyMzgxODM2Mn0.JCOcOBZPO2-VOYU6IsjvqBKwsieNPWecFhV2IP-NjeA"; // Replace with your token

    try {
      const response = await fetch(`${saroAPI}/getOnlineClerks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ retailerName }),
      });

      if (response.ok) {
        const responseData = await response.json();
        return responseData.data.length > 0;
      } else {
        console.error('API Error:', response.statusText);
        return false;
      }
    } catch (error) {
      console.error('API Crash Error:', error.message);
      return false;
    }
  };

  useEffect(() => {
    setModalState('dynamic');

    const checkClerks = async () => {
      const retailerName = "khazanay"; // Use actual retailer name
      const clerksAvailable = await checkClerkAvailability(retailerName);
      setIsLoading(false);

      if (!clerksAvailable) {
        setClerksAvailable(false);
        setShowNoClerksPopup(true);
      } else {
        setClerksAvailable(true);
      }
    };

    // Check clerk availability
    checkClerks();
  }, []);

  const handleButtonClick = () => {
    if (clerksAvailable) {
      onPageChange("Permission");
    }
  };

  if (isConfigLoading) {
    return <div></div>; // Show loading while fetching config
  }

  return (
    <div>
      {isLoading && (
        <div className="small-overlay">
          <div className="small-loader"></div>
        </div>
      )}

      {showNoClerksPopup && (
        <div className="centered-container2">

          <div className="contentDiv2">
            <h1 className="main-heading2" style={{ color: configData?.primaryColor || '#000' }}>
              {textData?.P1H1  } {/* Dynamically insert description */}
            </h1>
            <h2 className="sub-heading2" style={{ color: configData?.secondaryColor || '#333' }}>
              {textData?.P1H2 } {/* Dynamically insert description */}
            </h2>
            <h3 className="description-text2" style={{ color: configData?.preSecondaryColor || '#333' }} >
              {textData?.P1H3 } {/* Dynamically insert description */}
            </h3>

            <div className={window.innerWidth <= 768 ? "" : "footer-section2"}>
                <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  <span className="powered-by">{textData?.logo}</span>
                  <img src={footerlogo1} alt="Saro Logo" className="footer-logo" />
                </a>
              </div>

          </div>

          <div className={window.innerWidth <= 768 ? "imageDiv13" : "imageDiv12"}>
            <img  
            src={window.innerWidth <= 768 ? (imageData?.P1MobileImage) : (imageData?.P1image)} 
            alt="Clock" 
            id="cimage1" />
          </div>

        </div>
      )}

      {!showNoClerksPopup && !isLoading && clerksAvailable && (
        <div className="centered-container">
          <div className="contentDiv">
            <h1 className="main-heading" style={{ color: configData?.primaryColor }}>
              {textData?.P2H1 } {/* Dynamically insert welcomeMessage */}
            </h1>
            <h2 className="sub-heading" style={{ color: configData?.secondaryColor }}>
              {textData?.P2H2} {/* Dynamically insert welcomeMessage2 */}
            </h2>
            <h3 className="description-text" style={{ color: configData?.preSecondaryColor }}>
              {textData?.P2H3 } {/* Dynamically insert welcomeMessage2 */}
            </h3>
            <div className=" d-flex flex-column align-items-center">
            <button 
          className="body-btn" 
          style={{ backgroundColor: configData?.BtnColor}} // Dynamic color for body-btn
          onClick={handleButtonClick}
        >
          <img src={micIcon} alt='mic' className='micimg img-fluid ms-9' /> {textData?.P2B1 }
        </button>
        <button 
          className="body-btn-outline" 
          style={{ borderColor: configData?.BtnColor}} // Dynamic border and text color
          onClick={() => window.open("https://shoparonline.com/", "_blank")}
        >
          {textData?.P2B2 }
        </button>

            </div>
            <div className={window.innerWidth <= 768 ? "" : "footer-section"}>
                <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" className="footer-link">
                  <span className="powered-by">{textData?.logo}</span>
                  <img src={footerlogo1} alt="Saro Logo" className="footer-logo" />
                </a>
              </div>
          </div>
          <div className={window.innerWidth <= 768 ? "imageDiv11" : "imageDiv12"}>
  <img 
    src={window.innerWidth <= 768 ? (imageData?.P2MobileImage) : (imageData?.P2Image)} 
    alt="Brand" 
    id="cimage2" 
  />
</div>


        </div>
      )}
    </div>
  );
};

export default Body;
